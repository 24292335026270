import React, {useState} from 'react';
import { Row, Col } from 'antd';
import { Modal, Tooltip } from 'antd';
import { Button, CloseIcon, MinusIcon, PlusIcon, Subtitle } from '../shared';

interface FlupsySectionsModalProps {
    bucketsX: number; 
    bucketsY: number; 
    divisions: number; 
    bucketsData: any;
    onCancel: () => void;
}

const FlupsySectionsModal: React.FC<FlupsySectionsModalProps> = ({ bucketsX, bucketsY, divisions, onCancel, bucketsData }) => {

  const [zoom, setZoom] = useState(1);

  const onNodeViewWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!e.shiftKey) return;
    const z = e.deltaY < 0 ? zoom - 0.1 : zoom + 0.1;
    setZoom(Math.max(0.1, z));
  };

  const getLine = (y: number, x: number) => {
    let lineOutput = {
      id: 0,
      line_name: '',
    };
    bucketsData.lines.forEach((line: any) => {
      if (line.fluspy_position === `${y}-${x}`) {
        lineOutput = line;
      }
    });
    return lineOutput;
  }
  const renderBuckets = () => {
    const buckets = [];
    let lineNumber = 0;
    for (let y = 1; y <= bucketsY; y++) {
    
      buckets.push(
        <Row key={`row-${y}`} gutter={[8, 8]} justify="center" >
          {Array.from({ length: bucketsX }).map((_, x) => 
            {  lineNumber++;  return (
            <Col key={`col-${x}`} span={24 / bucketsX} style={{height:'fit-content'}}>
              <div className="bucket" style={{ height: `fit-content` }}>
                {Array.from({ length: divisions }).map((_, i) => {
                  const line = getLine(lineNumber, i+1);
                  return (
                    <a href={`/farms/${bucketsData.farm_id}/${line.id}`} key={`division-${i}`} className="division">
                      <span>{line.line_name}</span>
                    </a>
                  )
                })}
              </div>
            </Col>
          )}
          )}
        </Row>
      );
    }
    return buckets;
  };

  return (
    <>
      <Modal
        visible={true}
        onCancel={onCancel}
        closable
        closeIcon={<CloseIcon />}
        width='full'
        style={{ maxWidth: 1440 }}
        footer={null}
        >
            <div className='d-flex align-items-center mb-16'>
            <Tooltip
                className='d-flex align-items-center'
                title='Use Shift + Scroll to zoom'
            >
                <div
                className='zoom-icon-btn'
                onClick={() => setZoom(Math.max(0.1, zoom - 0.1))}
                >
                <MinusIcon />
                </div>
                <div className='ml-7 mr-7'>
                <Subtitle
                    color='black-2'
                    align='center'
                    size={0}
                    fontWeight={600}
                >
                    {zoom.toFixed(1)}
                </Subtitle>
                </div>
                <div className='zoom-icon-btn' onClick={() => setZoom(zoom + 0.1)}>
                <PlusIcon />
                </div>
            </Tooltip>
            </div>
            <div className='node-view' onWheel={onNodeViewWheel} style={{ zoom }}>
                <div className="bucket-grid">{renderBuckets()}</div>
            </div>
        </Modal>
    </>
  );
};

export default FlupsySectionsModal;

