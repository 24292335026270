import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ISeaweedFarm,
  ISeaweedLineResource,
} from '../../../../entities/farms.entities';
import { selectUserMeta, selectAccount } from '../../../../store/auth/auth.selector';
import useMenuHandler from '../useMenuHandler';
import { Table } from 'antd';
import classNames from 'classnames';
import LineFormModal from '../../../farm-modals/LineFormModal';
import { defaultDateFormat } from '../../../../util/toggleSecondMillisecond';
import Subtitle from '../../subtitle/Subtitle';
import { sendSingleRequest } from '../../../../apis';
import { loadFarmsData } from '../../../../store/farms/farms.actions';
import { showFeedback } from '../../../../store/ui/ui.actions';
import { compareString, labelNumber } from '../../../../lib/common.helpers';
import {
  amountDays,
  calcLineLastSize,
  getLineStatus,
} from '../../../../lib/farm.helpers';
import { selectLang } from '../../../../store/ui/ui.selector';
import { translate } from '../../../../lib/lang.helper';
import { SelectIsEditable } from '../../../../store/extra/extra.selector';
import DotsMenu from '../../dropdown-menu/DotsMenu';

interface Props {
  linesData: ISeaweedLineResource[];
  farmData: ISeaweedFarm;
  isChild?: boolean;
  onEditLineLocation: (line: ISeaweedLineResource) => void;
}

const SeaweedLinesTable = ({
  linesData,
  farmData,
  isChild,
  onEditLineLocation,
}: Props) => {
  const dispatch = useDispatch<any>();

  const userMeta = useSelector(selectUserMeta);
  const { redirectToLine } = useMenuHandler();
  const lang = useSelector(selectLang);
  const isEditable = SelectIsEditable(farmData.id);
  const columnsOrder = useSelector(selectAccount)?.mussel_line_columns_order;
  const [editLine, setEditLine] = useState<ISeaweedLineResource>();

  const deleteLine = async (line: ISeaweedLineResource) => {
    if (!window.confirm('Are you sure you want to delete this line?')) return;
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/line/lines/${line.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Line deleted successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message ?? 'Unknown error',
        }),
      );
    }
  };
  const changeTable = async (pagination: any, filter: any, sorter: any) => {
    const columnKey: any = sorter?.columnKey;
    const orders: any = sorter?.order;
    const data = { columnKey, orders, farmId: farmData.id };
    await sendSingleRequest(data, 'POST', 'api/farm/line-sorting', true);
  };
  const tempColumns = [
    {
      title: translate(lang, 'Line'),
      key: 'line_name',
      sorter: (a: ISeaweedLineResource, b: ISeaweedLineResource) =>
        compareString(a.line_name, b.line_name),
      render: (x: ISeaweedLineResource) => <span>{x.line_name}</span>,
    },
    {
      title: translate(lang, 'Tank area'),
      key: 'tank_area',
      sorter: (a: ISeaweedLineResource, b: ISeaweedLineResource) =>
        a.tank_area - b.tank_area,
      render: (x: ISeaweedLineResource) => <span>{`${x.tank_area} m²`}</span>,
    },
    {
      title: translate(lang, 'Date Seed'),
      key: 'seed_date',
      sorter: (a: ISeaweedLineResource, b: ISeaweedLineResource) => {
        if (a.growing_cycle) {
          return (
            Number(a.growing_cycle.main_seed.planned_date_seed) -
            Number(b.growing_cycle?.main_seed.planned_date_seed)
          );
        } else {
          return (a.line_idle ?? 0) - (b.line_idle ?? 0);
        }
      },
      render: (x: ISeaweedLineResource) =>
        x.growing_cycle ? (
          defaultDateFormat(x.growing_cycle.main_seed.planned_date_seed)
        ) : (
          <div className='d-flex flex-wrap'>
            <span className='min-width-74 pr-4'>
              {translate(lang, 'Line empty for')}
            </span>
            <Subtitle size={5} color='black' align='left' fontWeight={600}>
              {amountDays(x.line_idle)}
            </Subtitle>
          </div>
        ),
    },
    {
      title: translate(lang, 'Last assessment date'),
      key: 'last_assessment',
      render: (line: ISeaweedLineResource) => {
        const x = line.growing_cycle?.last_assessment?.assessment_date;
        const d =
          getLineStatus(line, userMeta?.line_assess_expire_days) ===
          'REQUIRE_ASSESSMENT';
        return (
          <span className={d ? 'text-red' : ''}>
            {defaultDateFormat(x, '-')}
          </span>
        );
      },
    },
    {
      title: translate(lang, 'Avg. Size'),
      key: 'condition',
      defaultSortOrder: null,
      sorter: (a: ISeaweedLineResource, b: ISeaweedLineResource) =>
        (calcLineLastSize(a)?.avg ?? 0) - (calcLineLastSize(b)?.avg ?? 0),
      render: (line: ISeaweedLineResource) => (
        <span>{labelNumber(calcLineLastSize(line)?.avg, 'mm')}</span>
      ),
    },
    {
      title: '',
      key: 'more',
      render: (d: ISeaweedLineResource) => (
        <div>
          {isEditable && (
            <DotsMenu
              items={[
                {
                  label: translate(lang, 'Edit'),
                  onClick: () => setEditLine(d),
                },
                {
                  label: translate(lang, 'Location'),
                  onClick: () => onEditLineLocation(d),
                },
                {
                  label: translate(lang, 'Delete'),
                  onClick: () => deleteLine(d),
                },
              ]}
            />
          )}
        </div>
      ),
    },
  ];

  const columns = (columnsOrder !== undefined && columnsOrder.length > 0) ? columnsOrder
  .map(orderKey => tempColumns.find(col => col.title === orderKey))
  .filter((col): col is typeof tempColumns[number] => Boolean(col)) : tempColumns;
  
  const topScrollRef = useRef<HTMLDivElement | null>(null);
  const bottomScrollRef = useRef<HTMLDivElement | null>(null);

  const handleTopScroll = () => {
    if (bottomScrollRef.current && topScrollRef.current) {
      bottomScrollRef.current.scrollLeft = topScrollRef.current.scrollLeft;
    }
  };
  const handleBottomScroll = () => {
    if (topScrollRef.current && bottomScrollRef.current) {
      topScrollRef.current.scrollLeft = bottomScrollRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    const scrollTop = window.document.getElementById('lines-table-scroll-top');
    const tables = window.document.getElementsByClassName('table--lines');
    if (scrollTop && tables.length > 0) {
      scrollTop.style.width = `${tables[0].clientWidth}px`;
    }
  }, [linesData]);

  return (
    <div>
      <div
        ref={topScrollRef}
        onScroll={handleTopScroll}
        style={{
          overflowX: 'auto',
          height: '20px',
          marginTop: '8px',
        }}
      >
        <div id='lines-table-scroll-top' style={{ width: '100%' }} />
      </div>
      <div
        ref={bottomScrollRef}
        onScroll={handleBottomScroll}
        style={{ overflowX: 'auto' }}
      >
        <Table
          rowKey={'id'}
          className={classNames(`table table--isFarm`, {
            'table--is__child': isChild,
          })}
          pagination={false}
          columns={columns}
          dataSource={linesData}
          onRow={dataRow => ({
            onClick: () => redirectToLine(dataRow.farm_id, dataRow.id),
          })}
          onChange={changeTable}
        />
      </div>
      {editLine && (
        <LineFormModal
          visible={true}
          lineData={editLine}
          onClose={() => setEditLine(undefined)}
          title='Edit line details'
          farmData={farmData}
        />
      )}
    </div>
  );
};

export default SeaweedLinesTable;
