import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Title } from '../../components/shared';
import MarkersMap from '../../components/shared/map/MarkersMap';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import {
  IMusselLineDetail,
  IOysterLineDetail,
  IOysterLineResource,
} from '../../entities/farms.entities';
import { IBoatResource, IBoatTrack } from '../../entities/boat.entities';
import { sendSingleRequest } from '../../apis';
import { formatNumber, hashColor } from '../../entities/util-functions';
import boat_png from '../../images/boat.png';
import { selectAccount, selectUserMeta } from '../../store/auth/auth.selector';
import { selectFarmsData } from '../../store/farms/farms.selector';
import {
  calcLineLastSize,
  calcSpecieLineDozens,
  getEstHarvestDate,
  getLineOysters,
  getLineStatus,
} from '../../lib/farm.helpers';
import { LINE_COLORS } from '../../lib/constant';
import { ILocation, TBusinessType } from '../../entities/general.entities';
import moment from 'moment';
import {
  defaultDateFormat,
  toMillisecond,
  toSecond,
} from '../../util/toggleSecondMillisecond';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import { selectLang, selectMapType } from '../../store/ui/ui.selector';
import { selectAllTags } from '../../store/extra/extra.selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck, faSquare } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { saveMapType } from '../../store/ui/ui.actions';
import { selectSizedOysters } from '../../store/utils/utils.selector';
import LinesFilterView, {
  checkLineFilter,
} from '../../components/lines/LinesFilterView';
import './styles.scss';

library.add(faSquareCheck, faSquare);

interface IMapLine {
  id: number;
  name: string;
  farm_id: number;
  points: ILocation[];
  color: string;
  is_urgent: boolean;
  seedPercent?: number;
  stage: number;
  type: TBusinessType;
  basket_count?: number;
  total_seeded_basket_count?: number;
  seedings?: any;
  direction?: string;
  storageType?: string;
}

interface ICountOf {
  total: number;
  empty: number;
  ready_harvest: number;
  require_assessment: number;
  catch_spat: number;
  growing_mussel: number;
}

interface IMapFarm {
  id: number;
  name: string;
  farm_number: string;
  bounds?: ILocation[] | null;
  countOf: ICountOf;
  onClick?: () => void;
}

type IOptionType =
  | 'ALL'
  | 'REQUIRE_ASSESSMENT'
  | 'READY_HARVEST'
  | 'CATCHING_SPAT';

const defaultZoom = 17;

const statusOptions = (lang: TLang | undefined) => [
  { id: '0', label: translate(lang, 'All'), value: 'ALL' },
  {
    id: '1',
    label: translate(lang, 'Require assessment'),
    value: 'REQUIRE_ASSESSMENT',
  },
  {
    id: '2',
    label: translate(lang, 'Ready to harvest'),
    value: 'READY_HARVEST',
  },
  { id: '3', label: translate(lang, 'Catching spat'), value: 'CATCHING_SPAT' },
];

const toLatLng = (x: any) => ({
  lat: Number(x.latitude),
  lng: Number(x.longitude),
});

const topPosition = (farm: IMapFarm) => {
  if (!farm.bounds || farm.bounds.length <= 0) {
    return { lat: 0, lng: 0 };
  }
  let res = farm.bounds[0];
  for (let i = farm.bounds.length - 1; i > 0; i--) {
    if (
      res.lat < farm.bounds[i].lat ||
      (res.lat === farm.bounds[i].lat && res.lng > farm.bounds[i].lng)
    )
      res = farm.bounds[i];
  }
  return res;
};

const FarmMarker: React.FC<{ farm: IMapFarm; lang: TLang | undefined }> = ({
  farm,
  lang,
}) => (
  <div className='farm-marker'>
    <div className='--header'>
      <span className='--name'>{farm.name}</span>
      <span className='--farm_number'>({farm.farm_number})</span>
    </div>
    <div className='--footer'>
      <Button
        size={5}
        width='small'
        type='fill'
        color='blue'
        onClick={farm.onClick}
      >
        {translate(lang, 'View')}
      </Button>
    </div>
  </div>
);

const mapOptions = [
  { id: 'roadmap', label: 'Roadmap', value: 'roadmap' },
  { id: 'satellite', label: 'Satellite', value: 'satellite' },
  { id: 'hybrid', label: 'Hybrid', value: 'hybrid' },
  { id: 'terrain', label: 'Terrain', value: 'terrain' },
];

interface IFilter {
  spatSource?: string;
  tagName?: string;
  sizeIndexes: number[];
  status: IOptionType;
}

interface ITrackLocation {
  boat: IBoatResource;
  boat_tracks: IBoatTrack[];
}

const FarmsMap = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const userMeta = useSelector(selectUserMeta);
  const allFarms = useSelector(selectFarmsData);
  const lineTags = useSelector(selectAllTags);
  const mapType = useSelector(selectMapType);
  const onGrowWaste = useSelector(selectAccount)?.oyster_crops;
  const oysterSpecies = useSelector(selectSizedOysters);
  const { redirectToFarm, redirectToFarmVisualLine, redirectToLine } =
    useMenuHandler();
  const timer = useRef<any>();

  const [center, setCenter] = useState<any>();
  const [boatTracks, setBoatTracks] = useState<ITrackLocation[]>([]);
  const [zoom, setZoom] = useState(defaultZoom);
  const [blurBit, setBlurBit] = useState(0);
  const [filter, setFilter] = useState<IFilter>({
    sizeIndexes: [],
    status: 'ALL',
  });
  const [popupView, setPopupView] = useState<any>();

  const loadLine = async (
    type: TBusinessType,
    lineId: number,
    location: ILocation,
  ) => {
    const response = await sendSingleRequest(
      {},
      'GET',
      `api/farm/line/lines/${lineId}`,
      true,
    );
    if (!response.status) return;
    const lnDtl = response.data as IMusselLineDetail | IOysterLineDetail;
    const musselDtl = lnDtl as IMusselLineDetail;
    const oysterDtl = lnDtl as IOysterLineDetail;
    const seedings = lnDtl.growing_cycle
      ? lnDtl.cycles[lnDtl.cycles.length - 1].seedings
      : undefined;
    if (seedings)
      seedings.sort((a, b) => a.planned_date_seed - b.planned_date_seed);
    const lastSeed = seedings ? seedings[seedings.length - 1] : undefined;
    const spatSrc = lnDtl.growing_cycle?.main_seed.spat_storage;

    setPopupView({
      position: location,
      onClose: () => setPopupView(undefined),
      view: lnDtl.growing_cycle ? (
        <div style={{ marginTop: '-8px', fontSize: '14px', fontWeight: '400' }}>
          <strong>{translate(lang, 'Line %s', lnDtl.line_name)}</strong>
          <div>
            {`First seeded on ${defaultDateFormat(
              lnDtl.growing_cycle.main_seed.planned_date_seed,
            )}`}
          </div>
          <div>
            {`Line full date on ${defaultDateFormat(
              lastSeed?.planned_date_seed,
            )}`}
          </div>
          {type === 'MUSSEL' ? (
            <div>{`${musselDtl.growing_cycle?.current_line_length} m growing`}</div>
          ) : type === 'OYSTER' ? (
            <div>{`${getLineOysters(oysterDtl, 'dozens')} dozens growing`}</div>
          ) : (
            <></>
          )}
          <div>{spatSrc ? `Spat source: ${spatSrc.source}` : 'Catch spat'}</div>
          {type === 'MUSSEL' ? (
            <div>
              {`${translate(lang, 'Last known size')}: ${
                calcLineLastSize(lnDtl)?.avg
              } mm`}
            </div>
          ) : type === 'OYSTER' ? (
            <>
              {calcSpecieLineDozens(oysterDtl, onGrowWaste, oysterSpecies)
                ?.filter(x => x.amount !== undefined && x.amount != 0)
                ?.map((x, i) => (
                  <div key={i}>
                    {`${x.name}: ${formatNumber(x.amount ?? 0)} dz`}
                  </div>
                ))}
              <div>
                {`${translate(lang, 'Total')}: ${getLineOysters(oysterDtl)} dz`}
              </div>
            </>
          ) : (
            <></>
          )}
          <div>
            {`${translate(lang, 'Estimated harvest Date')}: ${defaultDateFormat(
              getEstHarvestDate(lnDtl),
            )}`}
          </div>
        </div>
      ) : (
        <div style={{ marginTop: '-8px', fontSize: '14px', fontWeight: '400' }}>
          {`Line ${lnDtl.line_name} is empty`}
        </div>
      ),
    });
  };

  const { farmsData, linesData, maxStage } = useMemo(() => {
    let farmsData: IMapFarm[] = [],
      linesData: IMapLine[] = [];
    let maxStage = 0;
    for (let farm of allFarms) {
      let tmpFarm: IMapFarm = {
        id: farm.id,
        name: farm.name,
        farm_number: farm.farm_number,
        bounds: farm.bounds,
        countOf: {
          total: 0,
          empty: 0,
          ready_harvest: 0,
          require_assessment: 0,
          catch_spat: 0,
          growing_mussel: 0,
        },
      };
      for (let line of farm.lines) {
        if (
          line.growing_cycle &&
          line.growing_cycle.main_seed.stage > maxStage
        ) {
          maxStage = line.growing_cycle.main_seed.stage;
        }
        if (!checkLineFilter(line, filter, lineTags)) continue;
        const lineStatus = getLineStatus(
          line,
          userMeta?.line_assess_expire_days,
        );
        let color = LINE_COLORS[lineStatus];
        if (
          (filter.status === 'CATCHING_SPAT' && color !== 'CATCH_SPAT') ||
          (filter.status === 'READY_HARVEST' && color !== 'READY_HARVEST') ||
          (filter.status === 'REQUIRE_ASSESSMENT' &&
            color !== 'REQUIRE_ASSESSMENT')
        )
          continue;

        const tags =
          lineTags?.filter(
            x => !!x.color && x.tag_lines.some(y => y.line_id === line.id),
          ) ?? [];
        if (tags.length > 0 && tags[0].color) {
          color = tags[0].color;
        }
        if (line.points && line.points.length > 1) {
          let tmpLine: IMapLine = {
            id: line.id,
            name: line.line_name,
            farm_id: line.farm_id,
            points: line.points,
            color,
            is_urgent: !!line.is_urgent,
            stage: line.growing_cycle?.main_seed.stage ?? 0,
            type: farm.type,
          };
          if (farm.type === 'OYSTER' && line.growing_cycle) {
        
            const ol = line as IOysterLineResource;
            tmpLine.direction = ol.direction;
            tmpLine.basket_count = ol.basket_count;
            tmpLine.total_seeded_basket_count = ol.growing_cycle?.total_seeded_basket_count ?? 0;
            tmpLine.seedings = ol.growing_cycle?.seedings;
            tmpLine.storageType = ol.growing_cycle?.main_seed?.spat_storage?.seed_type ?? '';
            tmpLine.seedPercent = Math.min(
              1,
              (ol.growing_cycle?.current_basket_count ?? 0) / ol.basket_count,
            );
          }
          linesData.push(tmpLine);
        }
        if (lineStatus === 'EMPTY') {
          tmpFarm.countOf.empty++;
        } else if (lineStatus === 'READY_HARVEST') {
          tmpFarm.countOf.ready_harvest++;
        } else if (lineStatus === 'REQUIRE_ASSESSMENT') {
          tmpFarm.countOf.require_assessment++;
        } else if (lineStatus === 'CATCH_SPAT') {
          tmpFarm.countOf.catch_spat++;
        } else {
          tmpFarm.countOf.growing_mussel++;
        }
        tmpFarm.countOf.total++;
      }
      tmpFarm.onClick = () =>
        farm.type === 'MUSSEL'
          ? redirectToFarmVisualLine(farm.id)
          : redirectToFarm(farm.id);

      farmsData.push(tmpFarm);
    }
    return { farmsData, linesData, maxStage };
  }, [allFarms, lineTags, filter, userMeta]);

  const farmMarkers = farmsData
    .filter(f => f.bounds && f.bounds.length > 0)
    .map(f => ({
      location: topPosition(f),
      content: <FarmMarker farm={f} lang={lang} />,
      bounds: f.bounds,
    }));

  const lineMarkers = linesData.map(x => {
    let points = [...(x.points as ILocation[])];
    if (points[1].lat < points[0].lat) {
      points = [points[1], points[0]];
    }
    const location: ILocation = points[0];
    const urgent = x.is_urgent ? points[1] : null;
    if (x.seedPercent) {
      const seedPoint = {
        lat: points[0].lat + (points[1].lat - points[0].lat) * x.seedPercent,
        lng: points[0].lng + (points[1].lng - points[0].lng) * x.seedPercent,
      };
      points = [points[0], seedPoint, points[1]];
    }
    let color = x.color;
    if (x.stage > 0 && blurBit & (1 << (x.stage - 1))) {
      color = `${color.slice(0, 7)}20`;
    }
    return {
      id: x.id,
      location,
      points,
      urgent,
      basket_count: x.basket_count,
      total_seeded_basket_count: x.total_seeded_basket_count,
      seedings: x.seedings,
      onClick: () => redirectToLine(x.farm_id, x.id),
      color,
      type: x.storageType,
      content: x.name,
      onMouseOver: (p: ILocation) => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => loadLine(x.type, x.id, p), 500);
      },
      onMouseOut: () => {
        if (timer.current) clearTimeout(timer.current);
      },
    };
  });

  const boatMarkers = boatTracks.map(x => ({
    location: toLatLng(x.boat_tracks[x.boat_tracks.length - 1]),
    tracks: [toLatLng(x.boat_tracks[x.boat_tracks.length - 1])],
    content: (
      <>
        <div
          style={{ marginTop: '-10px', marginBottom: '5px', fontWeight: '500' }}
        >
          {moment(
            toMillisecond(x.boat_tracks[x.boat_tracks.length - 1].rec_time),
          ).fromNow()}
        </div>
        <div
          className='--boat-img'
          style={{
            backgroundColor: hashColor(x.boat.reg_number),
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <img src={boat_png} alt='boat' />
        </div>
      </>
    ),
  }));

  const moveCenter = (farm: any) => {
    if (!farm.bounds || farm.bounds.length <= 0) {
      window.alert(translate(lang, 'Location of this farm is not defined'));
    } else {
      setCenter({
        lat: parseFloat(farm.bounds[0].lat),
        lng: parseFloat(farm.bounds[0].lng),
      });
      setZoom(zoom > defaultZoom ? defaultZoom : zoom + 0.01);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await sendSingleRequest(
        { start_time: toSecond(Date.now() - 8 * 3600 * 1000) },
        'GET',
        'api/boat/boat-track',
        true,
      );
      if (res.status) {
        setBoatTracks(res.data);
      }
    })();
  }, []);

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative'>
        <div className='farms-map-page'>
          <div className='farms__header d-flex justify-content-between align-items-center'>
            <Title size={5} color='black-3' align='default' fontWeight={700}>
              {translate(lang, 'Farms Map')}
            </Title>
          </div>
          <div className='map-header'>
            <LinesFilterView
              filter={filter}
              onChange={v => setFilter({ ...filter, ...v })}
            />
            <Dropdown
              className='filter-option'
              label=''
              defaultValue={filter.status}
              options={statusOptions(lang)}
              onChange={(v: any) => setFilter({ ...filter, status: v })}
            />
          </div>
          <div className='map-content'>
            <div className='--map'>
              <div className='map-type-div'>
                <Dropdown
                  label=''
                  options={mapOptions}
                  value={mapType ?? 'roadmap'}
                  onChange={v => dispatch(saveMapType(v))}
                />
              </div>
              <MarkersMap
                markers={[...farmMarkers, ...lineMarkers, ...boatMarkers]}
                center={center}
                mapTypeId={mapType as any}
                zoom={zoom}
                popup={popupView}
              />
            </div>
            <div className='--farms'>
              <div style={{ maxHeight: '80vh', overflow: 'auto' }}>
                {farmsData.map(farm => (
                  <div
                    className='--farm-button'
                    key={farm.id}
                    onClick={() => moveCenter(farm)}
                  >
                    <div className='--name-number'>
                      <span style={{ marginRight: '3px' }}>{farm.name}</span>
                      <span style={{ fontSize: '0.8rem' }}>
                        ({farm.farm_number})
                      </span>
                    </div>
                    <div className='--location'>
                      {!!farm.bounds && farm.bounds.length > 0
                        ? `${farm.bounds[0].lat} ${farm.bounds[0].lng}`
                        : '-'}
                    </div>
                    <div className='--lines-count'>
                      <div className='--total-count'>
                        {translate(lang, 'Total %s lines', farm.countOf.total)}
                      </div>
                      <div className='--empty-count'>
                        {translate(lang, '%s lines empty', farm.countOf.empty)}
                      </div>
                      <div className='--ready_harvest-count'>
                        {translate(
                          lang,
                          '%s lines ready to harvest',
                          farm.countOf.ready_harvest,
                        )}
                      </div>
                      <div className='--require_assessment-count'>
                        {translate(
                          lang,
                          '%s lines require assessment',
                          farm.countOf.require_assessment,
                        )}
                      </div>
                      <div className='--catch_spat-count'>
                        {translate(
                          lang,
                          '%s lines catch spat',
                          farm.countOf.catch_spat,
                        )}
                      </div>
                      <div className='--growing-count'>
                        {translate(
                          lang,
                          '%s lines normal growing',
                          farm.countOf.growing_mussel,
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {maxStage > 0 && (
                <div className='--stages'>
                  {[...Array(maxStage).keys()].map(x => (
                    <div
                      key={x}
                      className={
                        x === 0 ? 'cursor-pointer' : 'mt-12 cursor-pointer'
                      }
                      onClick={() => setBlurBit(blurBit ^ (1 << x))}
                    >
                      <FontAwesomeIcon
                        icon={!(blurBit & (1 << x)) ? faSquareCheck : faSquare}
                        color='green'
                        size='lg'
                      />
                      <span className='ml-12'>
                        {translate(lang, 'Stage %s', x + 1)}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmsMap;