import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useWidth } from '../../util/useWidth';
import {
  Button,
  BreadcrumbComponent,
  Title,
  Subtitle,
  DropdownMenu,
  BgIcon,
  ExpandArrowIcon,
  OwnerIcon,
  ClockIcon,
  Spinner,
} from '../../components/shared';
import NameWithPercent from '../../components/shared/name-with-percent/NameWithPercent';
import Tooltip from '../../components/shared/tooltip/Tooltip';
import { getInterest } from '../../util/farmUtil';
import { sendSingleRequest } from '../../apis';
import { Image } from 'antd';
import farm_image from '../../images/farm.png';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import { loadSpatStorages } from '../../store/farms/farms.actions';
import { selectLang, selectSpin } from '../../store/ui/ui.selector';
import LinesTable from '../../components/shared/tables/LinesTable';
import { randomKey } from '../../lib/common.helpers';
import BulkHarvestModal from '../../components/farm-modals/BulkHarvestModal';
import LinesMobile from '../../components/shared/table-mobile/LinesMobile';
import { translate } from '../../lib/lang.helper';
import {
  selectAllFarms,
  SelectIsEditable,
} from '../../store/extra/extra.selector';
import InterestGrowth from '../../components/shared/tables-card/InterestGrowth';

interface IApiFarmCard {
  name: string;
  this_year: number;
  last_year: number;
  interest: any;
}

const Farm = () => {
  const width = useWidth();
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector(selectLang);
  const isSpinner = useSelector(selectSpin);
  const farmsData = useSelector(selectAllFarms);

  const params = useParams<{ idFarm: string }>();
  const currentFarm = farmsData.find(x => x.id === Number(params.idFarm));
  const isEditable = SelectIsEditable(Number(params.idFarm));

  const [stats, setStats] = useState<IApiFarmCard[]>([]);
  const [showBulkHarvest, setShowBulkHarvest] = useState(false);

  useEffect(() => {
    dispatch(loadSpatStorages());
  }, [dispatch]);

  const breadcrumbItems = getBreadcrumbMenu('FARM_DETAIL', {
    FARM_ID: params.idFarm,
    FARM_NAME: currentFarm?.name,
  });

  const loadStats = async () => {
    const response = await sendSingleRequest(
      { farm_id: params.idFarm },
      'GET',
      'api/overview/farm-budget-info',
      true,
    );
    if (response.status) {
      const newGeneralStats = response.data?.map((item: any) => {
        const interest = getInterest(
          item?.this_year,
          item?.last_year ? item?.last_year : null,
        );
        return { ...item, interest };
      });
      setStats(newGeneralStats);
    }
  };

  useEffect(() => {
    loadStats();
  }, []);

  const handleOnEdit = () => {
    history.push(`/farms/farm-edit/${params.idFarm}`);
  };

  const tooltipItems =
    currentFarm?.owners.map((x, i) => {
      return {
        title: `${x.title} - ${x.percent}%`,
        id: i + 1,
      };
    }) ?? [];

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative farms__line'>
        {width > 768 && (
          <div className='d-flex justify-content-between align-items-center pl-21 pr-15 pt-28 pb-28'>
            <BreadcrumbComponent items={breadcrumbItems} />
            <div className='d-flex align-items-center'>
              {currentFarm?.type === 'OYSTER' && (
                <div className='mr-12'>
                  <Button
                    color='green'
                    size={2}
                    width='middle'
                    type='fill'
                    onClick={() => setShowBulkHarvest(true)}
                  >
                    {translate(lang, 'Bulk Harvest')}
                  </Button>
                </div>
              )}
              {!currentFarm?.is_flupsy && (
                <>
                  {isEditable && (
                    <Link to={`/farms/${params.idFarm}/add-line`}>
                      <Button color='blue' size={2} width='middle' type='fill'>
                        {translate(lang, 'Add Line')}
                      </Button>
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <div className='farms__line-content'>
          <div className='d-flex justify-content-between white-card farms__line--header'>
            <div className='farms__line--header__name'>
              <div>
                <Title
                  size={5}
                  color='black-3'
                  align='default'
                  fontWeight={500}
                >
                  {currentFarm?.name}
                </Title>
                <Subtitle size={4} color='black' align='left' fontWeight={400}>
                  <span className='pr-6'>{currentFarm?.farm_number}</span>
                </Subtitle>
              </div>
              {width <= 768 && isEditable && (
                <div className='d-flex align-items-center'>
                  <DropdownMenu
                    data={currentFarm}
                    column='isFarms'
                    onEdit={handleOnEdit}
                    type='isRedirect'
                    isRedirect='isFarms'
                  />
                </div>
              )}
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <BgIcon color='orange' icon={<ExpandArrowIcon />} />
              <div className='pl-12'>
                <Subtitle size={3} color='black' align='left' fontWeight={400}>
                  {translate(lang, 'Area')}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {currentFarm?.area} ha
                </Subtitle>
              </div>
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <BgIcon color='blue' icon={<OwnerIcon />} />
              <div className='pl-12'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                  className='pb-4'
                >
                  {translate(lang, 'Owner')}
                </Subtitle>
                <Tooltip content={tooltipItems} position='bottom'>
                  <div className='tooltip d-flex'>
                    {currentFarm?.owners.map((x, i) => {
                      const key = randomKey();
                      return (
                        <div key={key} className='table__owner'>
                          <NameWithPercent
                            title={x.title}
                            percent={x.percent}
                            index={i + 1}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <BgIcon color='green' icon={<ClockIcon />} />
              <div className='pl-12'>
                <Subtitle size={3} color='black' align='left' fontWeight={400}>
                  {translate(lang, 'Average line empty per year')}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {currentFarm?.idle_avg === 1
                    ? '1 day'
                    : `${currentFarm?.idle_avg} days`}
                </Subtitle>
              </div>
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <Link to={`/farms/visual-lines/${params.idFarm}`}>
                <Image src={farm_image} style={{ maxWidth: '30px' }} />
              </Link>
            </div>
            {width > 768 ? (
              <div className={'d-flex align-items-center'}>
                {isEditable && (
                  <DropdownMenu
                    data={currentFarm}
                    column='isFarms'
                    onEdit={handleOnEdit}
                    type='isRedirect'
                    isRedirect='isFarms'
                  />
                )}
              </div>
            ) : (
              <>
                {!currentFarm?.is_flupsy && (
                  isEditable && (
                    <Link to={`/farms/${params.idFarm}/add-line`}>
                      <Button
                        color='blue'
                        size={1}
                        width='wide'
                        type='fill'
                        className='farms__line--header__btn'
                      >
                        {translate(lang, 'Add Line')}
                      </Button>
                    </Link>
                  )
                )}
              </>
            )}
          </div>
          {stats.length > 0 && (
            <div
              className='d-flex align-items-center pt-10 pb-20'
              style={{ columnGap: 25 }}
            >
              {stats.map((stat, i) => (
                <div
                  key={i}
                  className='d-flex bg-main align-items-center'
                  style={{ padding: '10px 16px' }}
                >
                  <Subtitle
                    size={4}
                    color='black'
                    align='left'
                    fontWeight={500}
                    className='mr-12'
                  >
                    <span className='mr-12'>
                      {`${stat.name} this year: ${stat.this_year}`}
                    </span>
                    <span className='ml-12 mr-12'>
                      {`${stat.name} last year: ${stat.last_year}`}
                    </span>
                  </Subtitle>
                  <div className='ml-12'>
                    <InterestGrowth
                      interest={stat.interest.interest}
                      isGrow={stat.interest.isGrow}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {currentFarm && (
            <>
              {width > 768 ? (
                <LinesTable farmData={currentFarm} />
              ) : (
                <LinesMobile farmData={currentFarm} />
              )}
            </>
          )}
        </div>
        {isSpinner && <Spinner position='global' />}
      </div>
      {showBulkHarvest && currentFarm && (
        <BulkHarvestModal
          farm={currentFarm}
          visible={true}
          type='OYSTER'
          onConfirm={() => setShowBulkHarvest(false)}
          onCancel={() => setShowBulkHarvest(false)}
        />
      )}
    </div>
  );
};

export default Farm;
