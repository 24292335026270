import { useDispatch, useSelector } from 'react-redux';
import { Modal, Radio } from 'antd';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Dropdown,
  Feedback,
  Input,
  RadioButton,
  Subtitle,
} from '../../shared';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { toMillisecond, toSecond } from '../../../util/toggleSecondMillisecond';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { addUtilData } from '../../../store/utils/utils.actions';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import {
  loadFarmsData,
  loadSpatStorages,
} from '../../../store/farms/farms.actions';
import { sendSingleRequest } from '../../../apis';
import { showFeedback } from '../../../store/ui/ui.actions';
import { ITimeRange } from '../../../entities/general.entities';
import { IOysterLineResource } from '../../../entities/farms.entities';
import { renderCustomError } from '.';
import { loadInventories } from '../../../store/inventories/inventories.actions';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import {
  SelectInventoriesByFarmID,
  SelectIsBudgetVisible,
  SelectOysterSpatsByFarmID,
  SelectSeasonsByFarmID,
} from '../../../store/extra/extra.selector';
import DirectionForm from '../../farm-util/DirectionForm';
import { getSpatSourceLabel, inventoryLabel } from '../../../lib/farm.helpers';
import { dozensToPieces, piecesToDozens } from '../../../lib/common.helpers';

interface IPickIvt {
  id: number;
  quantity: string;
}

interface IFormData {
  season_name: string;
  spat_storage_id: number | null;
  spat_amount_dz: number | null;
  spat_amount_pcs: number | null;
  planned_date_seed: number;
  planned_date_harvest: number;
  spat_size: number;
  spat_size_max: number | null;
  basket_count: number | null;
  spacing: number | null;
  density: number | null;
  comment: string | null;
  direction: string | null;
  time_tracks?: ITimeRange[];
  cost?: number | null;
  images: any[];
  parent_id?: number | null;
  picked_inventories?: IPickIvt[] | null;
}

const defaultForm: IFormData = {
  season_name: '',
  spat_storage_id: null,
  spat_amount_dz: null,
  spat_amount_pcs: null,
  planned_date_seed: moment().toDate().getTime(),
  planned_date_harvest: moment().toDate().getTime(),
  spat_size: 0,
  spat_size_max: null,
  basket_count: null,
  spacing: null,
  density: null,
  comment: null,
  direction: null,
  images: [],
  parent_id: null,
  time_tracks: [],
};

interface Props {
  visible: boolean;
  title?: string;
  lineData: IOysterLineResource;
  data?: any;
  updateID?: number;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  overConfirm?: (data: any) => Promise<any>;
  parentID?: number;
  onlyView?: boolean;
}

const OysterSeedModal = ({
  visible,
  title,
  lineData,
  data,
  updateID,
  onCancel,
  onConfirm,
  overConfirm,
  parentID,
  onlyView,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const spats = lineData.farm_is_flupsy ? SelectOysterSpatsByFarmID(lineData.farm_id) : SelectOysterSpatsByFarmID(lineData.farm_id) ;
  const seasonData = SelectSeasonsByFarmID(lineData.farm_id).map(x => ({
    id: x.id.toString(),
    label: x.name,
    value: x.name,
  }));
  const inventories = SelectInventoriesByFarmID(lineData.farm_id);
  const showBudget = SelectIsBudgetVisible(lineData.farm_id);

  const getDefaultInventory = () => {
    const s = inventories.filter(
      x => x.type.toLowerCase() === lineData.farming_method?.toLowerCase(),
    );
    if (s.length > 0) {
      return [{ id: s[0].id, quantity: '' }];
    } else {
      return [];
    }
  };

  const [isSeasonNew, setIsSeasonNew] = useState<'old' | 'new'>('old');
  const [errors, setErrors] = useState<any>();
  const [formData, setFormData] = useState<IFormData>(defaultForm);
  const [addSpatSizeRange, setAddSpatSizeRange] = useState(false);
  const [pickedInventories, setPickedInventories] = useState<IPickIvt[]>(
    data?.picked_inventories ?? getDefaultInventory(),
  );
  const [errorMsg, setErrorMsg] = useState<string>();
  const [disabled, setDisabled] = useState(false);

  const availablePieces: number = formData.spat_storage_id
    ? (spats.find(x => x.id === Number(formData.spat_storage_id))
        ?.available_weight ?? 0) + (updateID ? data.spat_amount_pcs : 0)
    : -1;
  const availableDozens: number = formData.spat_storage_id
    ? piecesToDozens(
        spats.find(x => x.id === Number(formData.spat_storage_id))
          ?.available_weight ?? 0,
      ) + (updateID ? data.spat_amount_dz : 0)
    : -1;

  const toggleAddRangeCheck = (val: boolean) => {
    setAddSpatSizeRange(val);
    if (val) updateForm('spat_size_max', formData.spat_size);
    else updateForm('spat_size_max', null);
  };
  const selectInventoryIds = (val: string[]) => {
    let pArr = val.map(x => ({ id: parseInt(x), quantity: '' }));
    for (let p of pickedInventories) {
      let i = pArr.findIndex(x => x.id === p.id);
      if (i > -1) pArr[i].quantity = p.quantity;
    }
    setPickedInventories(pArr);
  };
  const updateInventoryQuantity = (i: number, val: string) => {
    let tmp = [...pickedInventories];
    tmp[i].quantity = val;
    setPickedInventories(tmp);
  };
  const updateForm = (key: keyof IFormData, val: any) => {
    if (
      [
        'spat_amount_dz',
        'spat_amount_pcs',
        'basket_count',
        'cost',
        'spacing',
        'density',
      ].includes(key)
    ) {
      val = val.length <= 0 ? null : Number(val);
    } else if (['comment'].includes(key)) {
      val = val.length <= 0 ? null : val;
    }
    if (
      ['spat_storage_id', 'spat_amount_dz', 'spat_amount_pcs'].includes(key)
    ) {
      setErrors((prv: any) => ({
        ...(prv ?? {}),
        spat_storage_id: null,
        spat_amount: null,
      }));
    } else {
      setErrors((prv: any) => ({ ...(prv ?? {}), [key]: null }));
    }
    if (key === 'spat_storage_id') {
      const spat = spats.find(x => x.id === Number(val));
      let tmp = { ...formData, spat_storage_id: Number(val) };
      if (spat?.harvest) {
        if (spat.harvest.shell_length) {
          tmp.spat_size = spat.harvest.shell_length;
        }
        if (spat.harvest.shell_length_max) {
          setAddSpatSizeRange(true);
          tmp.spat_size_max = spat.harvest.shell_length_max;
        }
      }
      setFormData(tmp);
    } else if (!val) {
      setFormData({ ...formData, [key]: val });
    } else if (key === 'spat_amount_dz') {
      let tmp = {
        ...formData,
        spat_amount_dz: val,
        spat_amount_pcs: dozensToPieces(val),
      };
      if (tmp.basket_count) {
        tmp.density = Math.round((val / tmp.basket_count) * 10) / 10;
      }
      setFormData(tmp);
    } else if (key === 'spat_amount_pcs') {
      let tmp = {
        ...formData,
        spat_amount_pcs: val,
        spat_amount_dz: piecesToDozens(val),
      };
      if (tmp.basket_count) {
        tmp.density =
          Math.round((tmp.spat_amount_dz / tmp.basket_count) * 10) / 10;
      }
      setFormData(tmp);
    } else if (key === 'basket_count') {
      let tmp = { ...formData, basket_count: val };
      if (tmp.density && !tmp.spat_amount_dz) {
        tmp.spat_amount_dz = val * tmp.density;
        tmp.spat_amount_pcs = dozensToPieces(val * tmp.density);
      } else if (tmp.spat_amount_dz && !tmp.density) {
        tmp.density = Math.round((tmp.spat_amount_dz / val) * 10) / 10;
      } else if (tmp.spat_amount_dz && tmp.density) {
        tmp.density = Math.round((tmp.spat_amount_dz / val) * 10) / 10;
      }
      setFormData(tmp);

      if (pickedInventories.length === 1) {
        let tmp = [...pickedInventories];
        tmp[0].quantity = val.toString();
        setPickedInventories(tmp);
      }
    } else if (key === 'density') {
      let tmp = { ...formData, density: val };
      if (tmp.basket_count) {
        tmp.spat_amount_dz = val * tmp.basket_count;
        tmp.spat_amount_pcs = dozensToPieces(val * tmp.basket_count);
      } else if (tmp.spat_amount_dz) {
        tmp.basket_count = Math.ceil(tmp.spat_amount_dz / val);
      }
      setFormData(tmp);
    } else {
      setFormData({ ...formData, [key]: val });
    }
  };
  const validFormData = () => {
    let tmpErr = { ...(errors ?? {}) };
    let res = true;
    if (!formData.season_name) {
      tmpErr.season_name = translate(lang, 'Add name');
      res = false;
    }
    if (Number(formData.basket_count) === 0) {
      tmpErr.basket_count = translate(
        lang,
        'Number of %s cannot be 0',
        lineData.farming_method,
      );
      res = false;
    }
    if (formData.planned_date_seed > formData.planned_date_harvest) {
      tmpErr.planned_date_seed = translate(lang, '_invalid_seed_date');
      res = false;
    }
    if (
      data?.is_catch_spat !== true &&
      (!formData.spat_storage_id ||
        !formData.spat_amount_dz ||
        formData.spat_amount_dz < 0)
    ) {
      tmpErr.spat_amount = translate(lang, 'Select spat and amount');
      res = false;
    }
    if (
      data?.is_catch_spat !== true &&
      formData.spat_amount_dz &&
      formData.spat_amount_dz > availableDozens
    ) {
      tmpErr.spat_amount = translate(
        lang,
        '_max_spat_amount',
        `${availableDozens} dozens (${availablePieces} pieces)`,
      );
      res = false;
    }
    if (
      formData.spat_size_max !== null &&
      formData.spat_size_max < formData.spat_size
    ) {
      tmpErr.spat_size = translate(lang, 'Invalid spat size range');
      res = false;
    }
    if (
      formData.time_tracks &&
      toSecTimeTracks(formData.time_tracks) === false
    ) {
      setErrorMsg('_invalid_time_range');
      res = false;
    }
    let containersCnt = 0;
    for (let px of pickedInventories) {
      const ivt = inventories.find(x => x.id === px.id);
      if (!ivt) {
        setErrorMsg('Inventory is not found');
        res = false;
        break;
      }
      if (isNaN(Number(px.quantity)) || Number(px.quantity) <= 0) {
        setErrorMsg(translate(lang, '_invalid_ivt_qty', ivt.name));
        res = false;
        break;
      }
      if (Number(px.quantity) > ivt.available_quantity) {
        setErrorMsg(
          translate(
            lang,
            '_seed_floats_over',
            px.quantity,
            ivt.name,
            ivt.available_quantity,
          ),
        );
        res = false;
        break;
      }
      containersCnt += Number(px.quantity);
    }
    if (containersCnt !== Number(formData.basket_count)) {
      const cf = window.confirm(
        `You should put ${formData.basket_count} containers but you added ${containersCnt} containers. Are you sure?`,
      );
      if (!cf) return null;
    }
    setErrors(tmpErr);
    if (!res) return null;

    let validData = {
      ...formData,
      line_id: lineData.id,
      planned_date_seed: toSecond(formData.planned_date_seed),
      planned_date_harvest: toSecond(formData.planned_date_harvest),
    };
    if (!!parentID) {
      validData.parent_id = parentID;
    } else if (!updateID) {
      const ms = lineData.growing_cycle?.main_seed;
      if (ms) {
        if (!window.confirm(translate(lang, '_line_seed_make_sure')))
          return null;
        validData.parent_id = ms.id;
      }
    }
    if (pickedInventories.length > 0) {
      validData.picked_inventories = pickedInventories;
    }
    if (formData.time_tracks) {
      const tt = toSecTimeTracks(formData.time_tracks);
      if (tt) {
        validData.time_tracks = tt;
      } else {
        setErrorMsg('_invalid_time_range');
        return null;
      }
    }
    if (!updateID && !validData.parent_id && lineData.growing_cycle) {
      if (!window.confirm(translate(lang, '_line_seed_make_sure'))) return null;
      validData.parent_id = lineData.growing_cycle.main_seed.id;
    }
    return validData;
  };
  const handleConfirmClick = async () => {
    if (onlyView) return;
    let validData = validFormData();
    if (!validData) return;

    setErrorMsg(undefined);
    setDisabled(true);
    if (!seasonData.some(x => x.value === validData?.season_name)) {
      await dispatch(addUtilData('season', validData.season_name));
    }
    if (!!overConfirm) {
      const ovc = await overConfirm(validData);
      if (ovc !== true) {
        setDisabled(false);
        if (ovc.errors) setErrors(ovc.errors);
        if (ovc.message) setErrorMsg(ovc.message);
      }
      return;
    }
    const res = updateID
      ? await sendSingleRequest(
          validData,
          'PUT',
          `api/farm/line/seeding/${updateID}`,
          true,
        )
      : await sendSingleRequest(
          validData,
          'POST',
          `api/farm/line/oyster-seeding`,
          true,
        );
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(
            lang,
            res.data?.message ??
              (updateID
                ? 'Updated data successfully'
                : 'Seed data submitted successfully'),
          ),
          type: 'success',
        }),
      );
      dispatch(loadFarmsData());
      dispatch(loadSpatStorages());
      if (pickedInventories) {
        dispatch(loadInventories());
      }
      onConfirm(validData);
    } else {
      setDisabled(false);
      setErrorMsg(translate(lang, res.data?.message ?? 'Unknown error'));
      if (res.data?.errors) setErrors(res.data.errors);
    }
  };

  useEffect(() => {
    if (data) {
      let tmp: any = {};
      for (let k in formData) {
        tmp[k] = data[k] ?? (formData as any)[k];
        if (k === 'planned_date_seed' || k === 'planned_date_harvest') {
          tmp[k] = toMillisecond(tmp[k]);
        } else if (
          k === 'images' &&
          (tmp[k] === null || tmp[k] === undefined)
        ) {
          tmp[k] = [];
        }
      }
      if (data?.spat_storage && !tmp.spat_storage_id) {
        tmp.spat_storage_id = data.spat_storage.id;
      }
      if (tmp.time_tracks && tmp.time_tracks.length > 0) {
        tmp.time_tracks = tmp.time_tracks.map((x: any) => ({
          start_time: toMillisecond(x.start_time),
          finish_time: toMillisecond(x.finish_time),
        }));
      }
      if (showBudget && data.expense_items && data.expense_items.length > 0) {
        tmp.cost = data.expense_items.reduce(
          (p: any, c: any) => p + c.amount,
          0,
        );
      } else if (showBudget && data.cost) {
        tmp.cost = data.cost;
      }
      if (tmp.images) {
        tmp.images = tmp.images.map((x: any) =>
          typeof x === 'object' && x !== null ? x : parseImageUrlToFileItem(x),
        );
      }
      setAddSpatSizeRange(!!data.spat_size_max);
      setFormData(tmp);
    }
  }, [data, showBudget]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={600}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Oyster seed line')}
          </Subtitle>
        </div>
        {
          <div>
            {!!errorMsg && (
              <Feedback
                message={translate(lang, errorMsg)}
                type={'error'}
                theme='light'
                position={10}
                isGlobal
                onClose={() => setErrorMsg(undefined)}
              />
            )}
            {!data?.is_catch_spat ? (
              <>
                <Radio.Group
                  className='d-flex mt-14 mb-32'
                  onChange={e => setIsSeasonNew(e.target.value)}
                  value={isSeasonNew}
                >
                  <RadioButton
                    label={translate(lang, 'Existing season')}
                    value='old'
                  />
                  <RadioButton
                    className='ml-34'
                    label={translate(lang, 'New season')}
                    value='new'
                  />
                </Radio.Group>
                <div
                  className={
                    errors?.season_name ? 'invalid-form mb-16' : ' mb-16'
                  }
                >
                  {isSeasonNew === 'old' ? (
                    <Dropdown
                      label={translate(lang, 'Season name')}
                      placeholder={translate(lang, 'Choose Season')}
                      className='mr-16 w-100'
                      onChange={value => updateForm('season_name', value)}
                      options={seasonData}
                      value={formData.season_name}
                    />
                  ) : (
                    <Input
                      label={translate(lang, 'New Season')}
                      placeholder={translate(lang, 'Season name')}
                      type='text'
                      value={formData.season_name}
                      onChange={e => updateForm('season_name', e.target.value)}
                    />
                  )}
                  {!!errors?.season_name && (
                    <div className='invalid-feedback'>
                      {errors?.season_name}
                    </div>
                  )}
                </div>
                <div
                  className={
                    errors?.spat_amount || errors?.spat_storage_id
                      ? 'invalid-form mt-17'
                      : ' mt-17'
                  }
                >
                  <Dropdown
                    label={translate(lang, 'Spat')}
                    placeholder={translate(lang, 'Spat')}
                    className={`mb-${
                      formData.spat_storage_id === null ? '16' : '7'
                    }`}
                    value={getSpatSourceLabel(
                      formData.spat_storage_id ?? 0,
                      spats,
                    )}
                    options={spats
                      .filter(x => Number(x.available_weight) > 0)
                      .map(item => ({
                        id: item.id.toString(),
                        value: item.id.toString(),
                        label: getSpatSourceLabel(item.id, spats),
                      }))}
                    onChange={(value, evt) =>
                      updateForm('spat_storage_id', value)
                    }
                    showSearch={true}
                  />
                  {!!formData.spat_storage_id && (
                    <div>
                      <Input
                        label={`${translate(lang, 'Amount')}${
                          formData.spat_amount_pcs
                            ? ` ${formData.spat_amount_pcs} pieces`
                            : ''
                        }`}
                        placeholder={
                          availablePieces <= 0
                            ? translate(lang, 'Amount')
                            : translate(
                                lang,
                                '_max_spat_amount',
                                `${availableDozens} dz (${availablePieces} pieces)`,
                              )
                        }
                        type='number'
                        value={formData.spat_amount_dz?.toString() ?? ''}
                        max={availableDozens > 0 ? availableDozens : undefined}
                        onChange={e =>
                          updateForm('spat_amount_dz', e.target.value)
                        }
                        unit={'dz'}
                      />
                      {formData.spat_amount_dz !== availableDozens && (
                        <div
                          className='blue-0 mt-4 ml-4 cursor-pointer'
                          onClick={() =>
                            updateForm(
                              'spat_amount_dz',
                              availableDozens.toString(),
                            )
                          }
                        >
                          {translate(
                            lang,
                            '_full_spats_seeded',
                            getSpatSourceLabel(formData.spat_storage_id, spats),
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {!!errors?.spat_storage_id && (
                    <div className='invalid-feedback'>
                      {errors?.spat_storage_id}
                    </div>
                  )}
                  {!!errors?.spat_amount && (
                    <div className='invalid-feedback'>
                      {renderCustomError(errors?.spat_amount)}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className='d-flex justify-content-center'>
                {translate(lang, 'This is line catch spat')}
              </div>
            )}
          </div>
        }
        <div
          className={`mb-24 mt-16 ${
            errors?.planned_date_seed ? 'invalid-form' : ''
          }`}
        >
          <Datepicker
            label={translate(lang, 'Line seeding date')}
            defaultValue={formData.planned_date_seed}
            onChange={e =>
              updateForm(
                'planned_date_seed',
                e?.toDate().getTime() ?? moment().toDate().getTime(),
              )
            }
            required
          />
          {errors?.planned_date_seed && (
            <div className='invalid-feedback'>{errors?.planned_date_seed}</div>
          )}
        </div>
        <div className='mb-16'>
          <Datepicker
            label={translate(lang, 'Planned harvest date')}
            defaultValue={formData.planned_date_harvest}
            onChange={e => {
              if (
                formData.planned_date_seed !== undefined &&
                e &&
                e!.toDate().getTime() < formData.planned_date_seed
              ) {
                updateForm('planned_date_harvest', moment().toDate().getTime());
              } else {
                updateForm(
                  'planned_date_harvest',
                  e?.toDate().getTime() ?? moment().toDate().getTime(),
                );
              }
            }}
            required
          />
        </div>
        <div className='d-flex justify-content-between mb-16'>
          <div
            className={`w-100 mr-3 ${
              errors?.basket_count ? 'invalid-form' : ''
            }`}
          >
            <Input
              label={translate(lang, 'Number of %s', lineData.farming_method)}
              type='number'
              value={formData.basket_count?.toString() ?? ''}
              required
              onChange={e => updateForm('basket_count', Number(e.target.value))}
            />
            {errors?.basket_count && (
              <div className='invalid-feedback'>{errors?.basket_count}</div>
            )}
          </div>
          <div className='w-100 ml-3'>
            <Input
              label={translate(lang, 'dozens per %s', lineData.farming_method)}
              type='number'
              value={formData.density?.toString() ?? ''}
              dataType='density'
              onChange={e => updateForm('density', e.target.value)}
              unit='dz'
              required
            />
          </div>
        </div>
        <div className='mb-16'>
          <DirectionForm
            value={formData.direction}
            onChange={v => updateForm('direction', v)}
          />
        </div>
        {!!formData.time_tracks && (
          <TimeTrackInput
            data={formData.time_tracks}
            onChange={t => updateForm('time_tracks', t)}
            disabled={onlyView}
          />
        )}
        <div className='mb-7'>
          <CheckboxButton
            label={translate(lang, 'Add spat size range')}
            checked={addSpatSizeRange}
            onChange={e => toggleAddRangeCheck(e.target.checked)}
          />
        </div>
        {formData.spat_size_max === null ? (
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Spat Size')}
              type='number'
              value={formData.spat_size.toString()}
              unit='mm'
              required
              onChange={e => updateForm('spat_size', Number(e.target.value))}
            />
          </div>
        ) : (
          <div className='d-flex mb-17 justify-content-between'>
            <div
              className={`w-100 mr-3 ${
                errors?.spat_size ? 'invalid-form' : ''
              }`}
            >
              <Input
                label={translate(lang, 'Minimum spat size')}
                type='number'
                value={formData.spat_size.toString()}
                required
                onChange={e => updateForm('spat_size', Number(e.target.value))}
              />
              {errors?.spat_size && (
                <div className='invalid-feedback'>{errors?.spat_size}</div>
              )}
            </div>
            <div className='w-100 ml-3'>
              <Input
                label={translate(lang, 'Maximum spat size')}
                type='number'
                value={formData.spat_size_max.toString()}
                required
                onChange={e =>
                  updateForm('spat_size_max', Number(e.target.value))
                }
              />
            </div>
          </div>
        )}
        <div className='mb-17'>
          <Input
            label={translate(lang, 'Basket spacing')}
            type='number'
            value={formData.spacing?.toString() ?? ''}
            dataType='spacing'
            unit='mm'
            required
            onChange={e => updateForm('spacing', e.target.value)}
          />
        </div>
        {!updateID && (
          <div className='pb-17'>
            <Dropdown
              label={translate(lang, 'Select inventory')}
              placeholder={translate(lang, 'Select inventory')}
              mode='multiple'
              options={inventories.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: inventoryLabel(x),
              }))}
              value={pickedInventories.map(x => x.id.toString()) as any}
              onChange={(val: any) => selectInventoryIds(val)}
              disabled={onlyView}
            />
          </div>
        )}
        {pickedInventories.length > 0 && (
          <div className='mb-17'>
            {pickedInventories.map((item, idx) => (
              <Input
                label={inventoryLabel(inventories.find(t => t.id === item.id))}
                placeholder={translate(
                  lang,
                  'Maximum value is %s',
                  inventories.find(t => t.id === item.id)?.available_quantity,
                )}
                type='number'
                className='mb-15'
                key={item.id}
                value={item.quantity?.toString() ?? ''}
                onChange={e => updateInventoryQuantity(idx, e.target.value)}
                max={
                  inventories.find(t => t.id === item.id)?.available_quantity
                }
                disabled={onlyView}
              />
            ))}
          </div>
        )}
        {!!showBudget && (
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Cost')}
              type='number'
              value={formData.cost?.toString() ?? ''}
              dataType='cost'
              unit='$'
              onChange={e => updateForm('cost', Number(e.target.value))}
            />
          </div>
        )}
        <div className='mt-17 mb-17'>
          <Input
            label={translate(lang, 'Comment')}
            placeholder={translate(lang, 'Comment')}
            type='textarea'
            value={formData.comment ?? ''}
            onChange={e => updateForm('comment', e.target.value)}
          />
        </div>
        <div className='mb-17'>
          <PicturesWall
            label={translate(lang, 'Images')}
            fileList={formData.images}
            handleChangeImages={v => updateForm('images', v)}
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='red'
          className='rsp-btn ml-16'
          onClick={onCancel}
          disabled={disabled}
        >
          {translate(lang, 'Close')}
        </Button>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled || onlyView}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default OysterSeedModal;
